import { memo } from "react";

/* chapter 끝난후 보여주는 화면 */
const EndChapter = () => {
  return (
    <div className="chapter-end">
      <div className="inner">
        <strong>
          If you enjoyed
          <br className="mo" /> this ride,
          <br className="pc" />
          <br className="mo" />
          wait to see what’s
          <br className="mo" /> about to come
        </strong>
        <div className="btn-area">
          <button className="btn again">
            <span>explore again</span>
          </button>
        </div>
      </div>
      <footer className="footer">
        <div className="copyright">© anotherworld.</div>
      </footer>
    </div>
  );
};

export default memo(EndChapter);
