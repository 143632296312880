import { memo } from "react";

/* 첫 시작 이미지 로딩 현황 보여주는 화면 */
const MainLoading = () => {
  return (
    <div className="loading-new">
      <div className="loading-ani">
        {/* <!--  index start from 0  --> */}
        <div style={{ "--index": "0" }}></div>
        <div style={{ "--index": "1" }}></div>
        <div style={{ "--index": "2" }}></div>
        <div style={{ "--index": "3" }}></div>
        <div style={{ "--index": "4" }}></div>
        <div style={{ "--index": "5" }}></div>
        <div style={{ "--index": "6" }}></div>
        <div style={{ "--index": "7" }}></div>
        <div style={{ "--index": "8" }}></div>
        <div style={{ "--index": "9" }}></div>
        <div style={{ "--index": "10" }}></div>
        <div style={{ "--index": "11" }}></div>
        <div style={{ "--index": "12" }}></div>
        <div style={{ "--index": "13" }}></div>
        <div style={{ "--index": "14" }}></div>
        <div style={{ "--index": "15" }}></div>
        <div style={{ "--index": "16" }}></div>
        <div style={{ "--index": "17" }}></div>
        <div style={{ "--index": "18" }}></div>
        <div style={{ "--index": "19" }}></div>
        {/* <!--  index ended with (var(--num-dot) - 1)  --> */}
      </div>
      <div className="count">0%</div>
    </div>
  );
};

export default memo(MainLoading);
