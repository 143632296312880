import { useEffect } from "react";
import { useInView } from "react-intersection-observer";
import {
  EndChapter,
  ExplorePage,
  Header,
  MainLoading,
  MonCharacter,
  StartChapter,
} from "./common/components";
import "./styles/common.css";
import { initialize, landingStage } from "./utils/common";
function App() {
  const [observerRef, observerInView] = useInView();

  useEffect(() => {
    initialize();
  }, []);
  useEffect(() => {
    if (observerInView) {
      landingStage();
    }
  }, [observerInView]);
  return (
    <div id="container" className="container">
      {/* header 부분과 햄버거 메뉴 버튼과 메뉴 내용화면 */}
      <Header />
      {/* 시작전 이미지 다운로드 로딩 컴포넌트 */}
      <MainLoading />
      {/* <!-- #content --> */}
      <div className="cache"></div>drawImage
      <main id="content" className="content main-content">
        {/* 다운로드 후 둥둥 떠다니는 캐릭터 */}
        <MonCharacter />
        {/* 다운로드 처음으로 보여지는 페이지 혹은 chapter 끝나고 보여지는 페이지 */}
        <ExplorePage />
        {/* chapter page */}
        <div className="stage">
          <StartChapter />
          <div className="counter">0</div>
          <div ref={observerRef} style={{ position: "absolute", bottom: 1 }} />
        </div>
        {/*  chapter 끝난 후 page */}
        <EndChapter />
        {/* chapter 중간중간 넘길때 나타나는 구름 */}
        <div className="fog">
          <div className="wrapper">
            <canvas></canvas>
          </div>
        </div>
      </main>
    </div>
  );
}

export default App;
