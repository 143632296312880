import gsap from "gsap";
import { isMobile } from "react-device-detect";

/*! IE-Checker v1.0.0 | (c) 2007-2015 The GrotesQ | https://github.com/Unk/IE-Checker */
// !(function () {
//   var e = navigator.userAgent.toLowerCase();
//   if (-1 != e.indexOf("msie") || -1 != e.indexOf("trident")) {
//     var a = 11;
//     (e = /msie ([0-9]{1,}[\.0-9]{0,})/.exec(e)), e && (a = parseInt(e[1]));
//     var i = "";
//     (i += " is-ie"), (i += " ie" + a);
//     for (var t = a + 1; 11 >= t; t++) i += " lt-ie" + t;
//     document.getElementsByTagName("html")[0].className += i;
//   }
// })();

/* sound */

var startChk = false;

var bgm = new Audio("../static/sound/bgm.mp3");
var bgmVolume = 0.4;
var volObj = { x: 0 };
bgm.loop = true;
bgm.volume = volObj.x;
bgm.mute = true;
bgm.pause();

window.addEventListener("GAME_SOUND_MUTE", soundMuteToggler);
window.addEventListener("GAME_SOUND_START", soundStart);

function soundMuteToggler() {
  window.muted = !window.muted;
  if (window.muted) {
    //audio가 무음이 됐을때 처리

    // bgm 중지를 클릭했을때 발생하는 이벤트
    document.querySelector("#header .sound-btn").classList.add("mute"); //bgm 이 정지 됐을때 css추가해서 정지버튼 표시로 바뀜

    gsap.to(volObj, {
      x: 0,
      duration: 1,
      ease: "none",
      onUpdate: function () {
        bgm.volume = volObj.x; // volume 를 0으로 바꿈
      },
      onComplete: function () {
        bgm.muted = true; // 완료되면 무음
        bgm.pause(); // audio 중지
      },
    });
  } else {
    //audio 재생 시킬때
    document.querySelector("#header .sound-btn").classList.remove("mute"); // 무음 표시였던 audiobutton style 제거

    gsap.to(volObj, {
      x: bgmVolume,
      duration: 1,
      ease: "none",
      onStart: function () {
        bgm.play(); //audio 다시 플레이
        bgm.muted = false; //음소거 제거
      },
      onUpdate: function () {
        bgm.volume = volObj.x; // 소리크기 0.4
      },
    });
  }
}

/* 처음에 시작했을때 audio play */
function soundStart() {
  if (startChk) return;
  startChk = true;
  soundMuteToggler();
}

/**
 * ui Script
 * --------------------------------
 */

let w, h;
let initChk = false;

const loadArr = [];
let loadCount = 0;

let loadingFlag = false;

const mon = document.querySelector(".mon1 img");
let monCount = 0;
const monMaxCount = 100; // 총 image 갯수
const monWidth = 500; // image size
const monHeight = 600; // image size
const monArr = []; // mon image array
const stageName = "stage1";
const stageContainerArr = []; // web일때 보여줄 stage image array
const stageContainerLowerArr = []; // mobile때 보여줄 stage image array
const stageSceneMaxCount = 1016; // stage 총 이미지 갯수
const stageImgSrcArr = []; // mobile용 이미지
const stageImgSrcHighArr = []; //desktop용 이미지
const fogArr = []; //fog image 담는 array(스테이지 중간에 나타나는 구름)
const fog1 = 330; // fog 시작지점(stage image 330)
const fog2 = 710; // fog 두번째 시작지점 (staage image 710)
const fogLength = 70; //fog image 갯수
const endPoint = 946;

const chapterLength = 70;

/* chapter별 이미지 애니메이션을 보여줄 리스트 */
const chapters = [
  {
    start: 30,
    end: 30 + chapterLength,
  },
  {
    start: 130,
    end: 130 + chapterLength,
  },
  {
    start: 230,
    end: 230 + chapterLength,
  },
  {
    start: 430,
    end: 430 + chapterLength,
  },
  {
    start: 530,
    end: 530 + chapterLength,
  },
  {
    start: 630,
    end: 630 + chapterLength,
  },
  {
    start: 830,
    end: 830 + chapterLength,
  },
  {
    start: endPoint,
    end: 200,
  },
];
const chapterPoint = 0.5;
let chapterNum = 1;
let chapterProgress = 0;

let stageCutNum = 0; // 현재 스테이지 체크
let oldCutNum = -1;
let fogNum = 0;
const tourPointObj = { t: 0 };

let standardRatioW, standardRatioH;

let speed = 0;
const velocity = 0.88;
const backBreak = 0.14;
let autoPlayChk = false;
let speedInterval;

let monCanvas;
let monContext;
let stageCanvas;
let stageContext;
let fogCanvas;
let fogContext;

const moImg = new Image();
let stageImgUpscale;

window.muted = true;

gsap.defaults({ ease: "power2.out", duration: 0.8 });

/* 화면 크기에 따른 css변수 설정 */
function setUnitProperty() {
  //body 의 가로길이
  var windowWidth = document.querySelector("body").clientWidth,
    //body 의 세로길이
    windowHeight = document.querySelector("body").clientHeight,
    //가로 길이의 1퍼센트
    vw = 0.01 * document.querySelector("body").clientWidth,
    // 세로길이의 1퍼센트
    vh = 0.01 * document.querySelector("body").clientHeight;
  w = windowWidth;
  h = windowHeight;

  // CSS 변수 --vw에 vw 값
  document.documentElement.style.setProperty("--vw", "".concat(vw, "px"));
  // CSS 변수 --vh에 vh 값
  document.documentElement.style.setProperty("--vh", "".concat(vh, "px"));
  // CSS 변수 body의 전체 가로길이 --fullwidth
  document.documentElement.style.setProperty(
    "--fullwidth",
    "".concat(windowWidth, "px")
  );
  // CSS 변수 body의 전체 세로길이 --fullheight
  document.documentElement.style.setProperty(
    "--fullheight",
    "".concat(windowHeight, "px")
  );
  // CSS 변수 현재 브라우저 창의 내부 높이 --windowheight
  document.documentElement.style.setProperty(
    "--windowheight",
    "".concat(window.innerHeight, "px")
  );
  // focusSlide() 함수의 반환 값을 CSS 변수 '--swipeX'
  document.documentElement.style.setProperty(
    "--swipeX",
    "".concat(focusSlide(), "%")
  );
}

function focusSlide() {
  let ww = window.innerWidth; //현재 브라우저의 가로길이
  let wh = window.innerHeight; // 현재 브라우저의 높이
  let maxGap;
  if (ww < wh) {
    // 브라우저의 가로길이가 세로 길이보다 작으면 세로길이의 50%로 계산
    maxGap = ((wh - ww) / wh) * 50;
    return maxGap * chapterProgress;
  } else {
    return 0;
  }
}

function moveSpeed() {
  tourPointObj.t = 0;

  if (speed > 0) {
    //앞으로 움직일때
    if (stageCutNum === fog1) {
      // stsage 이미지가 330번째이면 fog aniumation 실행
      clearSpeed(); // 스피드 0
      autoPlayChk = true; // 자동실행
      tourPointObj.t = fog1;
      fogDrive(fog1 + fogLength);
    } else if (stageCutNum === fog2) {
      // stsage 이미지가 710번째이면 fog aniumation 실행
      clearSpeed();
      tourPointObj.t = fog2;
      fogDrive(fog2 + fogLength);
    } else if (stageCutNum === endPoint) {
      // stsage 이미지가 946번째이면 fog aniumation 실행
      clearSpeed();
      autoPlayChk = true;
      tourPointObj.t = endPoint;
      gsap.to(tourPointObj, {
        t: stageSceneMaxCount - 1,
        ease: "Sine.easeInOut",
        duration: 2.6,
        onUpdate: function () {
          if (oldCutNum === Math.floor(tourPointObj.t)) return;
          stageDraw(stageContainerArr, Math.floor(tourPointObj.t));
        },
        onComplete: function () {
          // 애니메이션이 끝나면 스테이지 번호를 최종 값으로 설정하고 자동 실행을 중지
          stageCutNum = stageSceneMaxCount - 1;
          autoPlayChk = false;
        },
      });
      gsap.to(".mon-wrapper", { opacity: 0, duration: 1.5, delay: 0.5 }); // 마지막 챕터 들어가면서 서서히 opacity 처리
      // chapter-end 화면의 텍스트를 페이드 인하며 표시
      gsap.fromTo(
        ".chapter-end .inner",
        { opacity: 0, yPercent: -30 },
        {
          opacity: 1,
          yPercent: 0,
          duration: 2,
          delay: 2,
          ease: "Cubic.easeOut",
          onStart: function () {
            //마지막 챕터 화면에 표시하고 초기화
            document.querySelector(".chapter-end").style.display = "flex";
            document.querySelector(".chapter-end").classList.add("reset");
          },
        }
      );
      //마지막 챕터 하단 푸터 표시
      gsap.fromTo(
        ".chapter-end .footer",
        { opacity: 0, yPercent: 30 },
        {
          opacity: 1,
          yPercent: 0,
          duration: 1,
          delay: 4,
          ease: "Cubic.easeOut",
          onStart: function () {},
        }
      );
    } else {
      tourPointObj.t = stageCutNum;

      if (chapterNum === 3) {
        stageDrive(fog1, "front", true); // 스테이지전환 1
      } else if (chapterNum === 6) {
        stageDrive(fog2, "front", true); // 스테이지너환 2
      } else if (
        chapterNum === 7 &&
        stageCutNum > chapters[chapterNum - 1].start
      ) {
        stageDrive(endPoint, "front", true); // 마지막 스테이지 전환(마지막 페이지로)
      } else {
        if (
          // 스테이지 컷 번호가 0이거나, fog 애니메이션 이후일 경우 처리
          stageCutNum === 0 ||
          stageCutNum === fog1 + fogLength ||
          stageCutNum === fog2 + fogLength
        ) {
          stageDrive(
            chapters[chapterNum - 1].start + chapterLength * chapterPoint,
            "front",
            false
          );
        } else {
          stageDrive(
            chapters[chapterNum].start + chapterLength * chapterPoint,
            "front",
            false
          );
        }
      }
    }
  } else {
    //뒤로 움직일때 케이스
    if (stageCutNum === 0) {
      return;
    } else if (stageCutNum === fog1 + fogLength) {
      clearSpeed();
      tourPointObj.t = fog1 + fogLength;
      fogDrive(fog1);
    } else if (stageCutNum === fog2 + fogLength) {
      clearSpeed();
      tourPointObj.t = fog2 + fogLength;
      fogDrive(fog2);
    } else {
      tourPointObj.t = stageCutNum;

      if (chapterNum === 4) {
        stageDrive(fog1 + fogLength, "back", true); //뒤로 갈때 스테이지 전환
      } else if (chapterNum === 7) {
        stageDrive(fog2 + fogLength, "back", true); //뒤로 갈때 스테이지 전환
      } else if (chapterNum === 1) {
        stageDrive(0, "back", false); // 시작 지점으로 이동
      } else {
        if (stageCutNum === fog1 || stageCutNum === fog2) {
          stageDrive(
            chapters[chapterNum - 1].start + chapterLength * 0.5,
            "back",
            false
          );
        } else {
          stageDrive(
            chapters[chapterNum - 2].start + chapterLength * 0.5,
            "back",
            false
          );
        }
      }
    }
  }

  if (speed > 0) {
    speed = speed * velocity; //앞으로 이동 속도 감속
  } else {
    speed = speed * (velocity - backBreak); // 뒤로이동할때 속도감속
  }

  if (Math.abs(speed) < 0.05) {
    clearSpeed();
  }

  function clearSpeed() {
    clearInterval(speedInterval);
    speed = 0;
  }
}

/* mon 위아래로 움직이게 보이는 animation함수 */
function monBounce() {
  gsap.to(".mon1", {
    duration: 2.8,
    repeat: -1,
    onUpdate: function () {
      // eslint-disable-next-line no-unused-expressions
      monCount !== Math.floor(monMaxCount * this.progress())
        ? monDraw(Math.floor(monMaxCount * this.progress()))
        : "";
    },
  });
}

//mon 캐릭터 배열에서 선택된 이미지를 캔버스로 그리는 함수
function monDraw(num) {
  monCount = num;
  monCanvas.width = monWidth;
  monCanvas.height = monHeight;
  try {
    monContext.drawImage(
      monArr[num],
      0,
      0,
      500, // 원본 이미지에서 사용할 가로크기
      600, // 원본 이미지에서 사용할 세로크기
      0,
      0,
      monWidth, //캔버스에 그릴 넓이
      monHeight // 캔버스에 그릴 높이
    );
  } catch (error) {
    console.log(error);
  }
}

function stageDraw(stage, num) {
  clearTimeout(stageImgUpscale);

  if (isMobile && !stageImgSrcArr[num]) {
    // 모바일이고 해당 이미지가 없을때 종료
    return;
  } else if (!isMobile && !stage[num]) {
    //모바일이 아니고 해당 이미지가 없을때 종료
    return;
  }

  //이전 스테이지 번호를 현재 num으로 갱신
  oldCutNum = num;

  if (stageImgSrcArr[num]) {
    if (
      moImg.src.split("images")[1] === stageImgSrcArr[num].split("images")[1]
    ) {
      if (isMobile) {
        //이미지를 캔버스에 그림
        canvasDraw(moImg);
      } else {
        //이미지를 캔버스에 그림
        canvasDraw(stage[num]);
      }
    } else {
      let img = new Image();
      if (isMobile) {
        if (stageContainerArr[num]) {
          //고해상도 이미지를 캔버스에 그림
          canvasDraw(stageContainerArr[num]);
        } else if (stageContainerLowerArr[num]) {
          //저해상도 이미지를 캔버스에 그림
          canvasDraw(stageContainerLowerArr[num]);
          stageImgUpscale = setTimeout(() => {
            //이미지가 로드되면 stageDraw 함수를 호풀하여 그림
            img.addEventListener("load", () => {
              stageDraw(stageContainerArr, stageCutNum);
            });
            img.src = stageImgSrcHighArr[num]; // 고해상도 이미지 로드
            stageContainerArr[num] = img; // 로드한 이미지 저장
          }, 100);
        } else {
          // 이미지가 없으면 모바일 이미지로 설정
          moImg.src = stageImgSrcArr[num];
        }
      } else {
        //데스크탑 환경에서는 스테이지 이미지를 그대로 그림
        img = stage[num];
        canvasDraw(img);
      }
    }
  }
  // 현재 스테이지 컷 번호로 업데이트.
  document.querySelector(".stage .counter").innerText = num;
}

function canvasDraw(i) {
  let pic;
  pic = i;

  const oriW = pic.naturalWidth;
  const oriH = pic.naturalHeight;
  let startX, startY;
  let bgW, bgH;
  let scaleRatio;

  const ww = window.innerWidth;
  const wh = window.innerHeight;
  stageCanvas.width = Math.max(ww, wh);
  stageCanvas.height = Math.max(ww, wh);

  if (ww > wh) {
    //윈도우 화면 가로가 세로보다 클때
    scaleRatio = wh / ww; //현재 윈도우 팡 비율 계산
    bgW = oriW;
    bgH = oriH * scaleRatio; // 세로 율계산
    startX = 0;
    startY = (oriH - bgH) * 0.37;
  } else {
    //화면 세로가 가로보다 클때
    scaleRatio = ww / wh;
    bgW = oriW * scaleRatio; // 가로 비율 계산
    bgH = oriH;
    startX = (oriW - bgW) * 0.5;
    startY = 0;
  }

  try {
    stageContext.drawImage(
      pic,
      startX, // x좌표 시작점
      startY, // y좌표 시작점
      bgW, // 이미지에서 사용할 가로크기
      bgH, // 이미지에서 사용할 세로크기
      0,
      0,
      stageCanvas.width, //캔버스에 그릴 넓이
      stageCanvas.height // 캔버스에 그릴 높이
    );
  } catch (error) {
    console.log(error);
  }
}

function stageDrive(goal, direction, again) {
  autoPlayChk = true; // 자동재생

  //애니메이션으로 tourPointObj.t 값을 목요 위치로 이동시킴
  gsap.to(tourPointObj, {
    t: goal, //목표값 설정하고 애니메이션 시작
    ease: "none",
    duration: Math.abs(goal - tourPointObj.t) * 0.048, // 현재 위치와 목표 위치 사이의 거리(goal - tourPointObj.t)에 따라 애니메이션의 지속 시간을 설정
    onUpdate: function () {
      stageCutNum = Math.floor(tourPointObj.t);
      if (oldCutNum === stageCutNum) return; // 이전 스테이지 번호랑 현재 스테이지 번호가 같으면 종료
      stageDraw(stageContainerArr, stageCutNum); // 현재 번호를 가져와서 스테이지를 그림
      chapterDraw(direction); // 방향에 따라 챕처를 그림
    },
    onComplete: function () {
      stageCutNum = tourPointObj.t; // 애니메이션 완료 후 현재 컷 번호를 목표 값(goal)으로 설정함
      autoPlayChk = false; // 자동재생 끄기
      if (again) moveSpeed(); // againtrue로 설정된 경우 moveSpeed 함수를 호출하여 다음 작업을 수행
    },
  });
}

function fogDrive(goal) {
  autoPlayChk = true; // 자동 재생
  gsap.to(tourPointObj, {
    t: goal, //목표값 설정하고 애니메이션 시작
    ease: "none",
    duration: 3, //3초동안
    onStart: function () {
      //애니메이션 시작했을때 안보이는 none 상태 이미지를 black로 설정
      document.querySelector(".fog").style.display = "block";
    },
    onUpdate: function () {
      if (oldCutNum === Math.floor(tourPointObj.t)) return; // 이전컷과 투어포인트가 같으면 종료
      stageDraw(stageContainerArr, Math.floor(tourPointObj.t)); // 현재 스테이지를 그림
      fogDraw(Math.floor(tourPointObj.t)); //방향에 따른 fog 그림
    },
    onComplete: function () {
      //애니메이션 완료되면 fog이미지 안보이게 설정
      document.querySelector(".fog").style.display = "none";

      stageCutNum = tourPointObj.t; // 현재 이미지 컷 위치를 투어보인트 지점으로 저장
      autoPlayChk = false; // 자동재생끄기
      // fog image 실행후 현재 chapter 번호 저장
      if (stageCutNum === fog1) {
        chapterNum = 3;
      } else if (stageCutNum === fog2) {
        chapterNum = 6;
      } else if (stageCutNum === fog1 + fogLength) {
        chapterNum = 4;
      } else if (stageCutNum === fog2 + fogLength) {
        chapterNum = 7;
      }
    },
  });
}

function fogDraw(frame) {
  let num;

  if (fogNum === frame) return; // frame랑 fogNum이 같으면 종료
  fogNum = frame;

  if (frame > fog1 && frame < fog1 + fogLength) {
    num = Math.floor(frame - fog1);
  } else if (frame > fog2 && frame < fog2 + fogLength) {
    num = Math.floor(frame - fog2);
  } else {
    return;
  }

  const ww = window.innerWidth;
  const wh = window.innerHeight;
  fogCanvas.width = ww;
  fogCanvas.height = wh;

  const oriW = fogArr[num].naturalWidth;
  const oriH = fogArr[num].naturalHeight;
  let startX, startY;
  let bgW, bgH;
  let scaleRatio;

  if (ww > wh) {
    //윈도우 화면 가로가 세로보다 클때
    scaleRatio = wh / ww; //현재 윈도우 팡 비율 계산
    bgW = oriW;
    bgH = oriH * scaleRatio; // 세로 율계산
    startX = 0;
    startY = (oriH - bgH) * 0.5;
  } else {
    //화면 세로가 가로보다 클때
    scaleRatio = ww / wh;
    bgW = oriW * scaleRatio; // 가로 비율 계산
    bgH = oriH;
    startX = (oriW - bgW) * 0.5;
    startY = 0;
    // console.log('세로', scaleRatio, startX, startY, bgW, bgH);
  }

  // context.drawImage(stage[num], posx, posy, canvas.width, canvas.height, 0, 0, canvas.width, canvas.height);
  try {
    fogContext.drawImage(
      fogArr[num], // 캔버스에 그릴 fog image
      startX, // x좌표 시작점
      startY, // y좌표 시작점
      bgW, // 이미지에서 사용할 가로크기
      bgH, // 이미지에서 사용할 세로크기
      0,
      0,
      fogCanvas.width, //캔버스에 그릴 넓이
      fogCanvas.height // 캔버스에 그릴 높이
    );
  } catch (error) {
    console.log(error);
  }
}

/* chapter이미지 length 로 chapter check */
function chapterDraw(direction) {
  let cNum;
  // chapter 1
  if (stageCutNum > chapters[0].start && stageCutNum < chapters[0].end) {
    cNum = 1;
    if (chapterNum !== cNum) {
      chapterNum = cNum;
    }
    // chapter 2
  } else if (stageCutNum > chapters[1].start && stageCutNum < chapters[1].end) {
    cNum = 2;
    if (chapterNum !== cNum) {
      chapterNum = cNum;
    }
    // chapter 3
  } else if (stageCutNum > chapters[2].start && stageCutNum < chapters[2].end) {
    cNum = 3;
    if (chapterNum !== cNum) {
      chapterNum = cNum;
    }
    // chapter 4
  } else if (stageCutNum > chapters[3].start && stageCutNum < chapters[3].end) {
    cNum = 4;
    if (chapterNum !== cNum) {
      chapterNum = cNum;
    }
    // chapter 5
  } else if (stageCutNum > chapters[4].start && stageCutNum < chapters[4].end) {
    cNum = 5;
    if (chapterNum !== cNum) {
      chapterNum = cNum;
    }
    // chapter 6
  } else if (stageCutNum > chapters[5].start && stageCutNum < chapters[5].end) {
    cNum = 6;
    if (chapterNum !== cNum) {
      chapterNum = cNum;
    }
    // chapter 7
  } else if (stageCutNum > chapters[6].start && stageCutNum < chapters[6].end) {
    cNum = 7;
    if (chapterNum !== cNum) {
      chapterNum = cNum;
    }
  }

  if (direction === "front") {
    // 전면 방향으로 실행할때 ex) chapter 1-> 2 로 넘어갈때
    if (
      stageCutNum >= chapters[chapterNum - 1].start &&
      stageCutNum <= chapters[chapterNum - 1].start + chapterLength
    ) {
      // chapter이 넘어갈때 서서히 나타나는 텍스트 애니메이션 실행
      textDraw();
    } else {
      // css --swipeX 변수 설정
      document.documentElement.style.setProperty("--swipeX", "".concat(0, "%"));
    }
  } else {
    // 뒤로 가기 로 움직일때 ex) chapter 2 -> 1
    if (
      stageCutNum >= chapters[chapterNum - 1].start &&
      stageCutNum <= chapters[chapterNum - 1].start + chapterLength
    ) {
      // chapter이 넘어갈때 서서히 나타나는 텍스트 애니메이션 실행
      textDraw();
    } else if (
      chapterNum > 1 &&
      stageCutNum < chapters[chapterNum - 1].start &&
      stageCutNum >= chapters[chapterNum - 2].start &&
      stageCutNum <= chapters[chapterNum - 2].start + chapterLength
    ) {
      // 뒤로갔으니까 chapter 감소 단 1 이상일때
      chapterNum--;
      textDraw();
    } else {
      // css --swipeX 변수 설정
      document.documentElement.style.setProperty("--swipeX", "".concat(0, "%"));
    }
  }

  function textDraw() {
    if (chapterNum > 7) return; //chapter가 7 이상일때는 종료

    // obj: 현재 챕터의 텍스트 요소, process: 현재 스테이지 진행 비율, value: 애니메이션 진행 값
    let obj, process, value;
    const cgap = 0.05; // 챕터 진행의 경계값을 정의함
    const chapterPointBefore = chapterPoint - cgap; // 애니메이션 시작 전의 경계
    const chapterPointAfter = chapterPoint + cgap; // 애니메이션 끝난후의 경계

    obj = document.querySelector(".chapter-" + chapterNum); // 챕터에 해당하는 html요소를 가져옴
    process = (stageCutNum - chapters[chapterNum - 1].start) / chapterLength; // 현재 스테이지 컷이 챕터에서 얼마나 진행되었는지를 백분율로 저장

    /* 애니메이션 종료 */
    gsap.killTweensOf(document.documentElement);
    gsap.killTweensOf(obj);
    // 프로세스 상태에 따라서 애니메이션 분기 처리
    if (process === 0 || process === 1) {
      obj.style.display = "none";
      chapterProgress = 0;
      document.documentElement.style.setProperty("--swipeX", "".concat(0, "%"));
      // 시작점 일때 초기 텍스트 스타일 설정
      if (process === 0) {
        if (obj.classList.contains("right")) {
          obj.style.opacity = 0;
          obj.style.filter = "blur(6px)";
          obj.style.transform = "translate(10%, -35%), scale(.4)";
        } else {
          obj.style.opacity = 0;
          obj.style.filter = "blur(6px)";
          obj.style.transform = "translate(-10%, -35%), scale(.4)";
        }
        // 종료지점점 일때 초기 텍스트 스타일 설정
      } else if (process === 1) {
        obj.style.opacity = 1;
        obj.style.filter = "blur(8px)";
        if (obj.classList.contains("right")) {
          obj.style.transform = "translate(-120%, -190%), scale(6)";
        } else {
          obj.style.transform = "translate(120%, -190%), scale(6)";
        }
      }
      // 챕터 진행이 시작점에 가까울 때의 상태 처리
    } else if (process < chapterPointBefore) {
      value = easeOutCubic(process / chapterPointBefore); // 애니메이션 설정
      chapterProgress = value * (obj.classList.contains("right") ? 1 : -1);
      // css변수 swipeX 값을 설정
      document.documentElement.style.setProperty(
        "--swipeX",
        "".concat(focusSlide(), "%")
      );

      obj.style.display = "block";
      obj.style.opacity = value;
      obj.style.filter = "blur(" + (8 * (1 - value) - 2) + "px)";

      /* 텍스트가 오른쪽에 있을경우랑 왼쪽에 있을 경우 스타일 조정하는 분기처리 */
      if (obj.classList.contains("right")) {
        // right
        obj.style.transform =
          "translate(" +
          10 * (1 - value) +
          "%, -" +
          (35 + 15 * value) +
          "%) scale(" +
          (0.4 + 0.6 * value) +
          ")";
      } else {
        // left
        obj.style.transform =
          "translate(" +
          -10 * (1 - value) +
          "%, -" +
          (35 + 15 * value) +
          "%) scale(" +
          (0.4 + 0.6 * value) +
          ")";
      }
      // 챕터 진행이 끝점에 가까울 때의 상태 처리
    } else if (process > chapterPointAfter) {
      value = easeInQuart(
        (process - chapterPointAfter) / (1 - chapterPointAfter)
      );
      chapterProgress =
        (1 - value) * (obj.classList.contains("right") ? 1 : -1);
      // css변수 swipeX 값을 설정
      document.documentElement.style.setProperty(
        "--swipeX",
        "".concat(focusSlide(), "%")
      );

      obj.style.display = "block";
      obj.style.opacity = 1;
      obj.style.filter = "blur(" + (10 * value - 2) + "px)";

      /* 텍스트가 오른쪽에 있을경우랑 왼쪽에 있을 경우 스타일 조정하는 분기처리 */
      if (obj.classList.contains("right")) {
        // right
        obj.style.transform =
          "translate(" +
          -120 * value +
          "%, -" +
          (50 + 140 * value) +
          "%) scale(" +
          (1 + 5 * value) +
          ")";
      } else {
        // left
        obj.style.transform =
          "translate(" +
          120 * value +
          "%, -" +
          (50 + 140 * value) +
          "%) scale(" +
          (1 + 5 * value) +
          ")";
      }
      // 텍스트가 중앙에 위치할 때의 상태 처리
    } else {
      value = 1;
      chapterProgress = obj.classList.contains("right") ? 1 : -1;
      document.documentElement.style.setProperty(
        "--swipeX",
        "".concat(focusSlide(), "%")
      );

      obj.style.display = "block";
      obj.style.opacity = 1;
      obj.style.filter = "blur(" + 0 + "px)";
      obj.style.transform =
        "translate(" + 0 + "%, -" + 50 + "%) scale(" + 1 + ")";
    }
    // 챕터가 끝에 도달하면 chapterNum을 증가
    if (process === 1) chapterNum++;
  }
}

/* 이미지 프리로드 로직(이미지 로딩으로 처음에 들어왔을때 로딩카운트 로직이랑 같이있음) */
//loadArr길이랑 총 이미지 길이를 계산해서 최초 진입 로딩 퍼센테이지 구현
// 좀 더 많은 양의 병렬처리를 하게끔 for문여러개로 분리해서 돌림
// stage 이미지가 너무 많아서 preLoad에서는 절반만 진행
async function preLoad() {
  // mon image를 loadArr에 경로를 저장하고 addImg를 통해서 image를 preload함
  for (let i = 1; i <= monMaxCount; i++) {
    const src =
      "../static/images/mon/webp/0" +
      (i < 100 ? (i < 10 ? "00" + i : "0" + i) : i) +
      ".webp";
    loadArr.push(src);
    await addImg(i - 1);
  }
  // fog image를 loadArr에 경로를 저장하고 addImg를 통해서 image를 preload함
  for (let k = 1; k <= fogLength; k++) {
    const src = "../static/images/fog/1000/" + (k < 10 ? "0" + k : k) + ".webp";
    loadArr.push(src);
    await addImg(monMaxCount + k - 1);
  }
  // stage image를 loadArr에 경로를 저장하고 addImg를 통해서 image를 preload함(1~127)
  for (let j = 1; j <= 127; j++) {
    const src =
      "../static/images/world/" +
      stageName +
      "/webp/" +
      (j < 1000 ? (j < 100 ? (j < 10 ? "000" + j : "00" + j) : "0" + j) : j) +
      ".webp";
    const lowerSrc =
      "../static/images/world/" +
      stageName +
      "/webp/low/" +
      (j < 1000 ? (j < 100 ? (j < 10 ? "000" + j : "00" + j) : "0" + j) : j) +
      ".webp";
    if (!isMobile) {
      loadArr.push(src);
      await addImg(monMaxCount + fogLength + j - 1);
    } else {
      loadArr.push(lowerSrc);
      await addImg(monMaxCount + fogLength + j - 1);
    }

    stageImgSrcArr.push(lowerSrc);
    stageImgSrcHighArr.push(src);
  }
  // stage image를 loadArr에 경로를 저장하고 addImg를 통해서 image를 preload함(128~254)
  for (let j = 128; j <= 254; j++) {
    const src =
      "../static/images/world/" +
      stageName +
      "/webp/" +
      (j < 1000 ? (j < 100 ? (j < 10 ? "000" + j : "00" + j) : "0" + j) : j) +
      ".webp";
    const lowerSrc =
      "../static/images/world/" +
      stageName +
      "/webp/low/" +
      (j < 1000 ? (j < 100 ? (j < 10 ? "000" + j : "00" + j) : "0" + j) : j) +
      ".webp";
    if (!isMobile) {
      loadArr.push(src);
      await addImg(monMaxCount + fogLength + j - 1);
    } else {
      loadArr.push(lowerSrc);
      await addImg(monMaxCount + fogLength + j - 1);
    }

    stageImgSrcArr.push(lowerSrc);
    stageImgSrcHighArr.push(src);
  }
  // stage image를 loadArr에 경로를 저장하고 addImg를 통해서 image를 preload함(255~381)
  for (let j = 255; j <= 381; j++) {
    const src =
      "../static/images/world/" +
      stageName +
      "/webp/" +
      (j < 1000 ? (j < 100 ? (j < 10 ? "000" + j : "00" + j) : "0" + j) : j) +
      ".webp";
    const lowerSrc =
      "../static/images/world/" +
      stageName +
      "/webp/low/" +
      (j < 1000 ? (j < 100 ? (j < 10 ? "000" + j : "00" + j) : "0" + j) : j) +
      ".webp";
    if (!isMobile) {
      loadArr.push(src);
      await addImg(monMaxCount + fogLength + j - 1);
    } else {
      loadArr.push(lowerSrc);
      await addImg(monMaxCount + fogLength + j - 1);
    }

    stageImgSrcArr.push(lowerSrc);
    stageImgSrcHighArr.push(src);
  }
  // stage image를 loadArr에 경로를 저장하고 addImg를 통해서 image를 preload함(382~전체의 절반)
  for (let j = 382; j <= stageSceneMaxCount / 2; j++) {
    const src =
      "../static/images/world/" +
      stageName +
      "/webp/" +
      (j < 1000 ? (j < 100 ? (j < 10 ? "000" + j : "00" + j) : "0" + j) : j) +
      ".webp";
    const lowerSrc =
      "../static/images/world/" +
      stageName +
      "/webp/low/" +
      (j < 1000 ? (j < 100 ? (j < 10 ? "000" + j : "00" + j) : "0" + j) : j) +
      ".webp";
    if (!isMobile) {
      loadArr.push(src);
      await addImg(monMaxCount + fogLength + j - 1);
    } else {
      loadArr.push(lowerSrc);
      await addImg(monMaxCount + fogLength + j - 1);
    }

    stageImgSrcArr.push(lowerSrc);
    stageImgSrcHighArr.push(src);
  }
}

// 이미지 태그를 생성하고 이미지 파일 경로를 설정한다음 처음에는 안보이게 진행
function addImg(num) {
  const img = document.createElement("img"); // img태그 생성
  img.onload = imgLoad; // 이미지 로드
  img.setAttribute("src", loadArr[num]); // loadArr자릿수에 맞게 이미지 태그에 src 경로를 넣음
  img.setAttribute("style", "opacity:0"); // 투명도 0
  img.setAttribute("data-index", num); // 인덱스 설정
  document.querySelector(".cache").appendChild(img);
}

// 이미지 로드 진행
function imgLoad(e) {
  // 로드된 이미지를 이벤트에서 가져옴
  const img = e.currentTarget;
  // 이미지에 저장된 data-index 속성에서 해당 이미지의 인덱스를 가져옴.
  const x = img.getAttribute("data-index");
  img.setAttribute("style", "opacity:1"); // 투명도 제거
  loadCount++; // 실행할때 loadcount 증가 순차적으로 loadCount 증가하면서 최초 로딩 퍼센테이지가 증가
  if (!loadingFlag) {
    updateLoading(); // 로딩이 진행중일때 실행 나머지 stage이미지 로드도 여기에서 진행함
  }

  // 로드된 이미지가 mon 문자열을 포함하는 경우, 몬스터 이미지 배열monArr에 저장
  if (loadArr[x].indexOf("mon") !== -1) {
    monArr[x] = img;
    // 로드된 이미지가 fog 문자열을 포함하는 경우, 몬스터 이미지 배열monArr에 저장
  } else if (loadArr[x].indexOf("fog") !== -1) {
    fogArr[x - monMaxCount] = img;
    // 로드된 이미지가 저해상도 스테이지 이미지를 가리킬 때, 저해상도 스테이지 배열stageContainerLowerArr에 저장
  } else if (loadArr[x].indexOf("webp/low") !== -1) {
    stageContainerLowerArr[x - monMaxCount - fogLength] = img;
    // 로드된 이미지가 stage1 을 가리킬 때 고해상도 스테이지 배열stageContainerArr에 저장하고 첫 번째 스테이지 이미지를 그림
  } else if (loadArr[x].indexOf("stage1") !== -1) {
    stageContainerArr[x - monMaxCount - fogLength] = img;
    if (x - monMaxCount === 0) stageDraw(stageContainerArr, 0); // 첫 번째 스테이지 이미지가 로드되면 스테이지를 그림
  }
}

/* loading 이 진행중일때 실행 그리고 남은 image preloading(이미지 수가 많아서 따로 preload 처리) */
function updateLoading() {
  if (document.querySelector(".loading-new")) {
    const percent = (loadCount / 678) * 100;
    document.querySelector(".loading-new .count").innerText =
      parseInt(percent) + "%";
  }
  if (loadCount === loadArr.length) {
    console.log("Complete"); // 완료
    loadingFlag = true;

    document.querySelector(".mon-wrapper").style.opacity = 0; // 투명도 0으로 설정
    monBounce(); // mon 이미지 애니메이션을 실행
    setTimeout(loadingComp, 500);

    /* update loading 실행 됏을때 나머지 스테이지 이미지를 preLoading함 */
    for (
      let j = stageSceneMaxCount / 2 + 1;
      j <= stageSceneMaxCount * 0.75;
      j++
    ) {
      const src =
        "../static/images/world/" +
        stageName +
        "/webp/" +
        (j < 1000 ? (j < 100 ? (j < 10 ? "000" + j : "00" + j) : "0" + j) : j) +
        ".webp";

      const lowerSrc =
        "../static/images/world/" +
        stageName +
        "/webp/low/" +
        (j < 1000 ? (j < 100 ? (j < 10 ? "000" + j : "00" + j) : "0" + j) : j) +
        ".webp";
      if (!isMobile) {
        loadArr.push(src);
        addImg(monMaxCount + fogLength + j - 1);
      } else {
        loadArr.push(lowerSrc);
        addImg(monMaxCount + fogLength + j - 1);
      }

      stageImgSrcArr.push(lowerSrc);
      stageImgSrcHighArr.push(src);
    }
    for (let j = stageSceneMaxCount * 0.75 + 1; j <= stageSceneMaxCount; j++) {
      const src =
        "../static/images/world/" +
        stageName +
        "/webp/" +
        (j < 1000 ? (j < 100 ? (j < 10 ? "000" + j : "00" + j) : "0" + j) : j) +
        ".webp";

      const lowerSrc =
        "../static/images/world/" +
        stageName +
        "/webp/low/" +
        (j < 1000 ? (j < 100 ? (j < 10 ? "000" + j : "00" + j) : "0" + j) : j) +
        ".webp";
      if (!isMobile) {
        loadArr.push(src);
        addImg(monMaxCount + fogLength + j - 1);
      } else {
        loadArr.push(lowerSrc);
        addImg(monMaxCount + fogLength + j - 1);
      }

      stageImgSrcArr.push(lowerSrc);
      stageImgSrcHighArr.push(src);
    }
  }
}

function loadingComp() {
  document.querySelector(".cache").style.display = "none";

  // 로딩 화면 애니메이션으로 사라지는 효과
  gsap.to(
    ".loading-new", // class 이름
    {
      opacity: 0,
      duration: 1,
      onComplete: function () {
        document.querySelector(".loading-new").style.display = "none";
      },
    }
  );

  // 로딩완료 후 mon 이미지 서서히 등장
  gsap.fromTo(
    ".mon-wrapper", // class 이름
    { opacity: 0 },
    {
      opacity: 1,
      duration: 1,
      delay: 1,
      onStart: () => {
        document.querySelector(".mon-wrapper").style.display = "block";
      },
    }
  );
  // 로딩 완료 후 explore button 서서히 등장
  gsap.fromTo(
    ".btn.explore", // class 이름
    { opacity: 0 },
    {
      opacity: 1,
      duration: 1,
      delay: 1,
      onStart: () => {
        document.querySelector(".btn.explore").style.display = "block";
      },
    }
  );

  document.querySelector(".page-content .section").style.opacity = 0; //explore 버튼 누르면 나오는 content 투명 처리

  resizeCalc(); // 시작했을때 현재 윈도우 크기를 가져와서 설정 비율 다시 계산
}

/* explore 버튼 이벤트 */
export const enterExplore = () => {
  if (document.querySelector(".btn.explore").classList.contains("clicked"))
    return;

  if (!bgm.played.length) {
    bgm.play(); // 오디오 재생
    window.dispatchEvent(new CustomEvent("GAME_SOUND_MUTE"));
  }

  document.querySelector("html").classList.remove("fixed");
  document.querySelector("body").classList.remove("restart");
  document.querySelector(".btn.explore").classList.add("clicked");

  /* explore 버튼이 애니메이션 효과로 사라짐 */
  gsap.to(".btn.explore", {
    opacity: 0,
    duration: 2,
    onComplete: () => {
      document.querySelector(".btn.explore").style.display = "none";
      document.querySelector(".btn.explore").classList.remove("clicked");
    },
  });

  /* header 가 애니메이션 효과로 나타남 */
  gsap.fromTo(
    "#header",
    { opacity: 0 },
    {
      opacity: 1,
      duration: 2,
      onStart: () => {
        document.querySelector("#header").style.display = "block";
      },
    }
  );
  gsap.set(".page-content .section", { opacity: 0 }); // page cotent 투명도 0으로 설정
  // page content 가 애니메이션 효과로 서서히 나타남
  gsap.fromTo(
    ".page-content .section1",
    { opacity: 0 },
    {
      opacity: 1,
      duration: 2,
      delay: 2,
      onStart: () => {
        document.querySelector(".section1 .wrapper").style.display = "block";
        document.querySelector(".section2 .wrapper").style.display = "block";
        document.querySelector(".section3 .wrapper").style.display = "block";
      },
    }
  );

  document.querySelector(".mon-wrapper").classList.add("enter");

  // page content scroll 위치 설정
  gsap.to(".section2", {
    scrollTrigger: {
      trigger: ".section2",
      start: "center bottom",
      end: "center bottom",
      toggleActions: "play none none reverse",
      // markers: true
    },
    opacity: 1,
    duration: 1,
    ease: "Cubic.easeOut",
  });
  // page content scroll 위치 설정
  gsap.to(".section3", {
    scrollTrigger: {
      trigger: ".section3",
      start: "center bottom",
      end: "center bottom",
      toggleActions: "play none none reverse",
      // markers: true
    },
    opacity: 1,
    duration: 1,
    ease: "Cubic.easeOut",
  });
  //mon image scroll 위치 고정
  const monScaleTween = gsap.to(".mon", {
    scrollTrigger: {
      trigger: ".page-content .gap",
      endTrigger: ".stage",
      start: "top top",
      end: "top top",
      scrup: true,
      onComplete: () => {
        document.querySelector(".mon-cover").classList.add("expand");
      },
      onUpdate: (self) => {
        document.querySelector(".mon-cover").style.transform =
          "scale(" + (1 - 0.3 * self.progress) + ")";
      },
    },
  });

  bodyScrollBlock(false);
  stageDraw(stageContainerArr, 0);

  return () => {
    monScaleTween.kill(); // scroll event 종료
  };
};

/* stage 활성화 이벤트 */
export function landingStage() {
  scrollTop(window, 0); // 스크롤 최상단 고정
  document.querySelector(".page-content").style.display = "none"; // 스테이지 진입시 content none처리
  //스테이지 진입시 monimage 크기 줄임
  document.querySelector(".mon-cover").style.transform =
    "scale(" + (1 - 0.3) + ")";

  bodyScrollBlock(true); //스크롤 못하게 fixed시킴

  document.querySelector(".stage").classList.add("active");
  document.querySelector("html").classList.add("fixed");

  document.querySelector("#header .logo").classList.add("overlay");
  document.querySelector("#header .gnb-btn").classList.add("overlay");
  document.querySelector("#header .sound-btn").classList.add("overlay");

  document.querySelector(".mon-cover").classList.add("expand");
}

/* 현재 윈도우 크기를 가져와서 비율에 맞에 계산에서 사이즈 비율을 구하는 함수 */
function resizeCalc() {
  // console.log(window.innerWidth);
  setUnitProperty();
  stageDraw(stageContainerArr, stageCutNum);
  fogDraw(stageCutNum);

  standardRatioW = monWidth / 1920 / (monWidth / window.innerWidth);
  standardRatioH = monHeight / 980 / (monHeight / window.innerHeight);

  // console.log(standardRatioW, standardRatioH);

  document.querySelector(".mon").style.transform =
    "translate(calc(-50% + var(--swipeX) * 2), -50%) scale(" +
    Math.max(standardRatioW, standardRatioH) +
    ")";
  document.querySelector(".mon .btn").style.transform =
    "scale(" + (1 + (1 - Math.max(standardRatioW, standardRatioH))) + ")";
}

// 햄버거 메뉴안에있는 각 링크를 초기화하는 함수
function gnbInit() {
  // GNB 내의 모든 <a> 요소를 선택함
  const gnbs = document.querySelectorAll("#gnb a");
  for (const i in gnbs) {
    // 각 항목이 객체의 고유한 속성인지 확인면서 접근
    if (Object.hasOwnProperty.call(gnbs, i)) {
      const el = gnbs[i];
      //click event 리스너 추가
      el.addEventListener("click", (e) => {
        e.preventDefault();

        // 기존에 실행 중인 애니메이션을 모두 중지
        gsap.killTweensOf(tourPointObj); // 투어 포인트 중지
        gsap.killTweensOf(".mon-wrapper"); // mon 애니메이션 중지
        gsap.killTweensOf(".chapter-end .inner"); // 챕터 엔드 애니메이션 중지
        gsap.killTweensOf(".chapter-end .footer"); // 챔처 엔드 푸터 애니메이션 중지

        // 현재 챕터의 텍스트가 보이는 상태면 숨김
        if (document.querySelector(".text-wrap .chapter-" + chapterNum))
          document.querySelector(
            ".text-wrap .chapter-" + chapterNum
          ).style.display = "none";

        document.querySelector(".gnb-btn").dispatchEvent(new Event("click"));
        // console.log(i, chapters[i].start, chapters[i].start + (chapterLength * chapterPoint));

        // 현재 스테이지가 활성화되지 않았다면 landingStage()를 호출하여 스테이지를 활성화
        if (!document.querySelector(".stage").classList.contains("active"))
          landingStage();

        if (
          document.querySelector(".chapter-end").classList.contains("reset")
        ) {
          document.querySelector(".chapter-end").style.display = "none";
          document.querySelector(".chapter-end").classList.remove("reset");
        }
        //mon이미지를 다시 투명도 1로 설정
        document.querySelector(".mon-wrapper").style.opacity = 1;

        stageCutNum = chapters[i].start + chapterLength * chapterPoint; // 현재 스테이지 컷 번호 계산
        chapterNum = Number(i) + 1; // 챕터번호 저장
        //스테이지를 그림
        stageDraw(stageContainerArr, stageCutNum);
        //방향 설정
        chapterDraw("front");
        //fog이미지 none처리
        document.querySelector(".fog").style.display = "none";
        //자동 재생 false
        autoPlayChk = false;
      });
    }
  }
}

export const initialize = async () => {
  if (initChk) return; // init 한번 실앻 됐으면 종료
  initChk = true; // initChk true로 변경

  /* 모든 이미지 캔버스 설정 */
  monCanvas = document.querySelector(".mon canvas");
  monContext = monCanvas?.getContext("2d");
  stageCanvas = document.querySelector(".land canvas");
  stageContext = stageCanvas.getContext("2d");
  fogCanvas = document.querySelector(".fog canvas");
  fogContext = fogCanvas.getContext("2d");

  const mainContent = document.querySelector(".main-content");

  /* page content, fog, explore 버튼 비활성화 */
  document.querySelector(".btn.explore").style.display = "none";
  document.querySelector(".fog").style.display = "none";
  document.querySelector(".section1 .wrapper").style.display = "none";
  document.querySelector(".section2 .wrapper").style.display = "none";
  document.querySelector(".section3 .wrapper").style.display = "none";

  document
    .querySelector(".chapter-end .btn")
    .addEventListener("click", function () {
      if (document.querySelector(".chapter-end").classList.contains("reset")) {
        document.querySelector(".chapter-end").classList.remove("reset");
        resetStage(); //마지막 챕터일때 버튼을 클릭하면 다시 처음화면으로 돌아가는 리셋 이벤트 최초 init에서도 실행시킴
      }
    });

  window.addEventListener("resize", resizeCalc); //화면비율을 가져옴

  document.querySelector(".gnb-btn").addEventListener("click", (e) => {
    e.preventDefault();

    if (document.querySelector(".gnb-btn").classList.contains("active")) {
      if (!document.querySelector(".stage").classList.contains("active"))
        bodyScrollBlock(false); // gnb btn 이 존재했을때 스크롤 허용
      document.querySelector("body").classList.remove("gnb-hidden"); // 메뉴 숨김 제거
    } else {
      bodyScrollBlock(true); // gnb btn 이 존재하지않으면 스크롤 막음
      document.querySelector("body").classList.add("gnb-hidden"); // 메뉴를 숨김
    }

    document.querySelector(".logo").classList.toggle("active");
    document.querySelector(".gnb-btn").classList.toggle("active");
    document.querySelector(".gnb-wrap").classList.toggle("active");
  });

  document.querySelector(".sound-btn").addEventListener("click", (e) => {
    window.dispatchEvent(new Event("GAME_SOUND_MUTE"));
  });

  preLoad(); // 이미지 프리로드

  gnbInit(); // 각 메뉴 초기화

  resizeCalc(); // 사이즈 초기화

  scrollTop(window, 0); // 상단고정

  bodyScrollBlock(true); // 스크롤 막음

  // 모든 .text-wrap 안의 .text 요소들을 숨김
  for (const i in document.querySelectorAll(".text-wrap .text")) {
    if (
      // 각 요소가 객체의 고유한 속성인지 확인
      Object.hasOwnProperty.call(
        document.querySelectorAll(".text-wrap .text"),
        i
      )
    ) {
      // 해당하는 .text 요소를 선택하여 display를 none으로 설정해 화면에서 비활성화
      const element = document.querySelectorAll(".text-wrap .text")[i];
      element.style.display = "none";
    }
  }
  // mainContent에 마우스 휠 이벤트 추가
  mainContent.addEventListener("wheel", function (e) {
    //스테이지가 활성화되지않았거나 자동재생중이거나, 챕터가 리셋일때는 동작 중지
    if (
      !document.querySelector(".stage").classList.contains("active") ||
      autoPlayChk ||
      document.querySelector(".chapter-end").classList.contains("reset")
    )
      return;
    // 마우스 휠의 수직 이동 값을 speed로 저장
    speed = e.deltaY;
    // 휠 이벤트가 발생하면 함수를 호출하여 다음 스텝으로 이동
    goToStep();
  });
  // mainContent 요소에 터치 시작, 이동, 종료 이벤트 리스너를 추가
  // 모바일 기기에서 스와이프 동작을 처리하기 위해 각 이벤트 리스너를 설정.
  mainContent.addEventListener("touchstart", handleTouchStart);
  mainContent.addEventListener("touchmove", handleTouchMove);
  mainContent.addEventListener("touchend", handleTouchEnd);

  // 모바일 기기일 경우, 이미지가 로드될 때마다 `canvasDraw` 함수를 호출하여 이미지를 그림
  if (isMobile) {
    moImg.addEventListener("load", canvasDraw);
  }
};

function goToStep() {
  moveSpeed(); //스테이지에서 마우스 휠 deltaY 값을 판단해서 앞, 뒤로 움직이게 이벤트 처리하는 함수
}

function resetStage() {
  stageCutNum = 0; // 현재 stage number 를 초기화함
  chapterNum = 1; // chapter 1로 초기화함

  // explore화면으로 돌아갈때 애니메이션 설정
  gsap.to("#content", {
    opacity: 0,
    duration: 2,
    ease: "Sine.easeIn",
    onStart: () => {
      // 스테이지를 비활성화함
      document.querySelector(".stage").classList.remove("active");
      // Explore 화면을 다시 시작함
      document.querySelector("body").classList.add("restart");
    },
    onComplete: () => {
      document.querySelector("html").classList.remove("fixed");
      //page content 섹션들을 비활성화함
      document.querySelector(".section1 .wrapper").style.display = "none";
      document.querySelector(".section2 .wrapper").style.display = "none";
      document.querySelector(".section3 .wrapper").style.display = "none";
      // logo, menu, sound 버튼을 비활성화함
      document.querySelector("#header .logo").classList.remove("overlay");
      document.querySelector("#header .gnb-btn").classList.remove("overlay");
      document.querySelector("#header .sound-btn").classList.remove("overlay");
      // mon이미지를 다시 크기를 늘림
      document.querySelector(".mon-wrapper").classList.remove("enter");
      document.querySelector(".mon-cover").classList.remove("expand");
      document.querySelector(".mon-cover").removeAttribute("style");
      // 마지막 챔터 화면은 비활성화하고 pagecontent는 활성화시킴
      document.querySelector(".chapter-end").style.display = "none";
      document.querySelector(".page-content").style.display = "block";
      //header 비활성화
      document.querySelector("#header").style.display = "none";
      //explore 버튼 비활성화
      document.querySelector(".btn.explore").style.display = "none";
      // fog 이미지를 비활성화함
      document.querySelector(".fog").style.display = "none";
      // stage 초기화
      stageDraw(stageContainerArr, stageCutNum);

      gsap.to("#content", {
        opacity: 1,
        duration: 2,
        ease: "Sine.easeOut",
        onComplete: () => {
          loadingComp(); // 완료되면 loadingComp 함수를 실행
        },
      });
    },
  });
}

let lastTouch, touchStartEvent;
let startPosition = {};
let dragging = false;

/* mobile stage이동할때 사용할 터치 이벤트 터치 시작점을 가져옴 */
const handleTouchStart = function (e) {
  touchStartEvent = e;
  startPosition = { x: e.touches[0].clientX, y: e.touches[0].clientY };
  dragging = true;
};
/* mobile stage이동할때 사용할 터치 이벤트 터치 시작점 시작 점 기준으로 양수 음수 판단해서 앞으로갈지 뒤로갈지 처리 */
const handleTouchMove = function (e) {
  let touch = { x: e.touches[0].clientX, y: e.touches[0].clientY };
  if (!lastTouch) {
    lastTouch = { x: touch.x, y: touch.y };
  }
  handleMove(touch.x, touch.y, touch.x - lastTouch.x, touch.y - lastTouch.y);
  lastTouch = { x: touch.x, y: touch.y };
};
// 터치가 끝난 시점 이벤트 처리
const handleTouchEnd = function (e) {
  lastTouch = null;

  dragging = false;
};

const handleMove = function (x, y, xMove, yMove) {
  if (!dragging) {
    // 드래그 상태가 아니면 종료
    return;
  }
  // 스테이지가 활성화되있지않고 오토플레이 상태이고, 마지막 챕터의 리셋상태이면 종료
  if (
    !document.querySelector(".stage").classList.contains("active") ||
    autoPlayChk ||
    document.querySelector(".chapter-end").classList.contains("reset")
  )
    return;

  if (yMove === 0) return;
  speed = -yMove;
  // 터치 이벤트가 발생하면 함수를 호출하여 다음 스텝으로 이동
  goToStep();
};

// 스크롤 막는 함수
let scrollHeight = 0;
function bodyScrollBlock(chk) {
  if (chk) {
    //스크롤 생성안하고 막고싶을때
    scrollHeight = scrollTop(document);
    document.querySelector("body").classList.add("hidden");
    document.querySelector("body").style.position = "fixed";
    document.querySelector("body").style.top = -scrollHeight + "px";
  } else {
    // 스크롤을 생성하고 스크롤 가능하게 할 때
    document.body.removeAttribute("style");
    document.querySelector("body").classList.remove("hidden");
    scrollTop(document, scrollHeight);
  }
}
// 스크롤 위치를 선택하는 함수
function scrollTop(el, value) {
  if (value === undefined) {
    return el.pageYOffset;
  } else {
    if (el === window || el.nodeType === 9) {
      el.scrollTo(el.pageXOffset, value);
    } else {
      el.pageYOffset = value;
    }
  }
}
//애니메이션 상태를 정의하는 함수
function easeOutCubic(x) {
  return 1 - Math.pow(1 - x, 3);
}
//애니메이션 상태를 정의하는 함수
function easeInQuart(x) {
  return x * x * x * x;
}
