import { memo } from "react";
import ExploreBtn from "./ExploreBtn";

/* 둥둥 떠다니는 캐릭터  */
const MonCharacter = () => {
  return (
    <>
      <div className="mon-wrapper">
        <div className="mon mon1">
          <div className="mon-cover">
            <canvas />
          </div>
          <ExploreBtn />
        </div>
      </div>
    </>
  );
};

export default memo(MonCharacter);
